<template>
  <div class="home">
    <div class="box" ref="box">
      <a-button type="primary" @click="PrintListBtn">点菜单</a-button>
      <a-button type="primary" @click="tBtn">退菜单</a-button>
      <a-button type="primary" @click="cBtn">催菜单</a-button>
      <a-button type="primary" @click="nBtn">Primary</a-button>
      <!-- <a-button type="primary" @click="jBtn">交接班</a-button> -->
      <a-modal
        :getContainer="()=>$refs.box"
        :visible="visible"
        @ok="handleOk"
        okText="打印"
        @cancel="handleCancel"
      >
        <!-- :mask="false" -->
        <ul>
          <!-- <li v-for="item in list" :key="item">
            <h2 class="h2">{{item.name}}</h2>
            <h2 class="h2">召陵区文汇书店</h2>
            <h2>【点菜整单】</h2>
            <h2>桌台：3</h2>
            <div class="p1">
            <p>
                <span>服务员:SXF</span>
            </p>
            <p>
                <span>人数：1</span>
            </p>
            </div>
            <p>下单时间：10-29 15:00：00</p>
            <p>账单号：11254584441125</p>
            <a-divider dashed />
            <p class="p2">
            <span>品名</span>
            <span>数量</span>
            </p>
            <a-divider dashed>(面类)</a-divider>
            <p class="p3">
            <span>雪碧</span>
            <span>X1份</span>
            </p>
            <p class="p4">
            <span>红茶</span>
            <span>x1个</span>
            </p>
            <a-divider dashed>(面食)</a-divider>
            <p class="p5">
            <span>牛肉面</span>
            <span>x1份</span>
            </p>
            <a-divider dashed>(酒水)</a-divider>
            <p class="p6">
            <span>奶茶[果粒]</span>
            <span>x1杯</span>
            </p>
            <div class="p7">
            <img src="../assets/er.jpg" alt="">
            </div>
          </li>-->
          <li class="boxli">
            <h2 class="h2">召陵区文汇书店</h2>
            <h2>【点菜整单】</h2>
            <h2>桌台：3</h2>
            <div class="p1">
              <p>
                <span>服务员:SXF</span>
              </p>
              <p>
                <span>人数：1</span>
              </p>
            </div>
            <p>下单时间：10-29 15:00：00</p>
            <p>账单号：11254584441125</p>
            <a-divider dashed />
            <p class="p2">
              <span>品名</span>
              <span>数量</span>
            </p>
            <a-divider dashed>(面类)</a-divider>
            <p class="p3">
              <span>雪碧</span>
              <span>X1份</span>
            </p>
            <p class="p4">
              <span>红茶</span>
              <span>x1个</span>
            </p>
            <a-divider dashed>(面食)</a-divider>
            <p class="p5">
              <span>牛肉面</span>
              <span>x1份</span>
            </p>
            <a-divider dashed>(酒水)</a-divider>
            <p class="p6">
              <span>奶茶[果粒]</span>
              <span>x1杯</span>
            </p>
            
          </li>
        </ul>
      </a-modal>
      <a-modal
        :getContainer="()=>$refs.box"
        :visible="visible2"
        @ok="handleOk2"
        okText="打印"
        @cancel="handleCancel2"
      >
        <!-- :mask="false" -->
        <ul>
          <!-- <li v-for="item in list" :key="item">
            <h2 class="h2">{{item.name}}</h2>
          </li>-->
          <li class="boxli">
            <h2 class="h2">召陵区文汇书店</h2>
            <h2>【退菜整单】</h2>
            <h2>桌台：3</h2>
            <div class="p1">
              <p>
                <span>服务员:李师</span>
              </p>
              <p>
                <span>人数：1</span>
              </p>
            </div>
            <p>下单时间：10-29 15:00：00</p>
            <p>账单号：11254584441125</p>
            <a-divider dashed />
            <p class="p2">
              <span>品名</span>
              <span>数量</span>
            </p>
            <a-divider dashed>(面类)</a-divider>
            <p class="p3">
              <span>雪碧</span>
              <span>X1份</span>
            </p>
            <p class="p4">
              <span>红茶</span>
              <span>x1个</span>
            </p>
            <a-divider dashed>(面食)</a-divider>
            <p class="p5">
              <span>牛肉面</span>
              <span>x1份</span>
            </p>
            <a-divider dashed>(酒水)</a-divider>
            <p class="p6">
              <span>奶茶[果粒]</span>
              <span>x1杯</span>
            </p>
            
          </li>
        </ul>
      </a-modal>
      <a-modal
        :getContainer="()=>$refs.box"
        :visible="visible3"
        @ok="handleOk3"
        okText="打印"
        @cancel="handleCancel3"
      >
        <!-- :mask="false" -->
        <ul>
          <!-- <li v-for="item in list" :key="item">
            <h2 class="h2">{{item.name}}</h2>
          </li>-->
          <li class="boxli">
            <h2 class="h2">召陵区文汇书店</h2>
            <h2>【催菜整单】</h2>
            <h2>桌台：3</h2>
            <div class="p1">
              <p>
                <span>服务员:张三</span>
              </p>
              <p>
                <span>人数：1</span>
              </p>
            </div>
            <p>下单时间：10-29 15:00：00</p>
            <p>账单号：11254584441125</p>
            <a-divider dashed />
            <p class="p2">
              <span>品名</span>
              <span>数量</span>
            </p>
            <a-divider dashed>(面类)</a-divider>
            <p class="p3">
              <span>雪碧</span>
              <span>X1份</span>
            </p>
            <p class="p4">
              <span>红茶</span>
              <span>x1个</span>
            </p>
            <a-divider dashed>(面食)</a-divider>
            <p class="p5">
              <span>牛肉面</span>
              <span>x1份</span>
            </p>
            <a-divider dashed>(酒水)</a-divider>
            <p class="p6">
              <span>奶茶[果粒]</span>
              <span>x1杯</span>
            </p>
            
          </li>
        </ul>
      </a-modal>
      <a-modal
        :getContainer="()=>$refs.box"
        :visible="visible4"
        @ok="handleOk4"
        okText="打印"
        @cancel="handleCancel4"
      >
        <!-- :mask="false" -->
        <ul>
          <!-- <li v-for="item in list" :key="item">
            <h2 class="h2">{{item.name}}</h2>
          </li>-->
          <li class="boxli">
            <h2 class="h2">会员专用</h2>
            <p>价格：0.1元</p>
            <p>少糖</p>
            <p>多冰</p>
            <p>备注 ：无</p>
            <p>11-01 08:05:05</p>
          </li>
        </ul>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      visible: false,
      visible2: false,
      visible3: false,
      visible4: false,
      list
    };
  },
  created() {},
  methods: {
    PrintListBtn() {
      // this.visible = true;
      // console.log('dd',)
      '{ "key": "print", "print_name": "local", "type": "kedan", "content_list": [ {"val":"******青年餐厅******", "type": "text",  "size":12, "lineHeight": 20},  {"val":"******客单******", "type": "text",  "size":12, "lineHeight": 20},  {"val":"******桌台: (8号)吉祥******", "type": "text",  "size":12, "lineHeight": 20},  {"val":"人数: 8 ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"开台时间: 2021-01-21 10:00:00 ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"服务员: 李东林 ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"------------------------------------ ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"-------------------面食--------------- ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"品名               单价             数量 ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"牛肉面            20                1份 ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"大雪碧            8                  1份 ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"-------------------热菜--------------- ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"品名               单价             数量 ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"(退)牛肉面            20                1份 ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"(赠)大雪碧            8                  1份 ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"------------------------------------ ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"总金额:                                     120.35", "type": "text",  "size":18, "lineHeight": 20},  {"val":"------------------------------------ ", "type": "text",  "size":12, "lineHeight": 20},  {"val":"下单时间 2010-01-20 12:00:00:45 ", "type": "text", "size":12, "lineHeight": 20},  ] }'
    },
    handleOk() {
      console.log("aaaaa");
      this.visible = false;
    },
    handleCancel() {
      console.log("Clicked cancel button");
      this.visible = false;
    },

    handleOk2() {
      console.log("aaaaa");
      this.visible2 = false;
    },
    handleCancel2() {
      console.log("Clicked cancel button");
      this.visible2 = false;
    },
    handleOk3() {
      console.log("aaaaa");
      this.visible3 = false;
    },
    handleCancel3() {
      console.log("Clicked cancel button");
      this.visible3 = false;
    },
    handleOk4() {
      console.log("aaaaa");
      this.visible4 = false;
    },
    handleCancel4() {
      console.log("Clicked cancel button");
      this.visible4 = false;
    },
    tBtn() {
      this.visible2 = true;
    },
    cBtn() {
      this.visible3 = true;
    },
    nBtn() {
      this.visible4 = true;
    }
  }
};
</script>
<style lang="less" scoped>
li {
  list-style: none;
}
.home {
  .box {
    padding-top: 200px;
    padding-left: 40%;
    button {
      margin-right: 20px;
    }
    .boxli {
      .p1 {
        display: flex;
        p:nth-of-type(1) {
          margin-right: 150px;
        }
        p:nth-of-type(2) {
          margin-left: 150px;
        }
      }
      .p2 {
        span:nth-of-type(1) {
          // font-size: 20px;
          // font-weight: bold;
          margin-right: 180px;
        }
        span:nth-of-type(2) {
          // font-size: 20px;
          // font-weight: bold;
          margin-left: 180px;
        }
      }
      .p3 {
        span:nth-of-type(1) {
          font-size: 20px;
          font-weight: bold;
          margin-right: 180px;
        }
        span:nth-of-type(2) {
          font-size: 20px;
          font-weight: bold;
          margin-left: 160px;
        }
      }
      .p4 {
        span:nth-of-type(1) {
          font-size: 20px;
          font-weight: bold;
          margin-right: 180px;
        }
        span:nth-of-type(2) {
          font-size: 20px;
          font-weight: bold;
          margin-left: 160px;
        }
      }
      .p5 {
        span:nth-of-type(1) {
          font-size: 20px;
          font-weight: bold;
          margin-right: 150px;
        }
        span:nth-of-type(2) {
          font-size: 20px;
          font-weight: bold;
          margin-left: 160px;
        }
      }
      .p6 {
        span:nth-of-type(1) {
          font-size: 20px;
          font-weight: bold;
          margin-right: 120px;
        }
        span:nth-of-type(2) {
          font-size: 20px;
          font-weight: bold;
          margin-left: 160px;
        }
      }
      .p7 {
        img {
          width: 50px;
          height: 50px;
        }
      }
      .p8 {
        img {
          width: 60px;
          height: 30px;
        }
      }
    }
  }
}
</style>
